import { Box, Grid } from '@mui/material'
//REACT ROUTER DOM
import { Link } from 'react-router-dom'

//CSS
import styles from '../footer/style.module.css'

//IMAGENS
const instagram = require('../../assets/images/instagram.png')
const facebook = require('../../assets/images/facebook.png')
const whatsapp = require('../../assets/images/whatsapp.png')
const playstore = require('../../assets/images/google_play_badge.png')
const appstore = require('../../assets/images/Download_App_Store.png')

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Grid>
        <div className={styles.container}>
          <div>
            <div className={styles.redes_sociais}>
              <a
                href="https://www.instagram.com/vokereofc/"
                className={styles.link_sociais}
              >
                <img
                  src={instagram}
                  className={styles.instagram}
                  alt="Logo do Instagram"
                />
              </a>
              <a
                href="https://www.facebook.com/vokereofc/"
                className={styles.link_sociais}
              >
                <img
                  src={facebook}
                  className={styles.facebook}
                  alt="Logo do Facebook"
                />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5599984151682&text=Preciso%20de%20ajuda!"
                className={styles.link_sociais}
              >
                <img
                  src={whatsapp}
                  className={styles.whatsapp}
                  alt="Logo do Whatsapp"
                />
              </a>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.footer_lista}>
              <h4>Vamos negociar?</h4>

              <Link to="/Franqueado">
                <p>Franqueado</p>
              </Link>
              <Link to="/Parceiro">
                <p>Parceria</p>
              </Link>
            </div>
            <div className={styles.footer_lista}>
              <h4>Faça parte do time</h4>

              <Link to="/Entregador">
                <p>Entregador</p>
              </Link>
            </div>
            <div className={styles.footer_lista}>
              <h4>Quem somos</h4>

              <Link to="/Sobre">
                <p>Vokerê</p>
              </Link>
            </div>
            <div className={styles.footer_lista}>
              <h4>Termos de uso</h4>

              <Link to="/termos">
                <p>Clique aqui para ver os termos</p>
              </Link>
            </div>
          </div>
        </div>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}>
          <p className={styles.baixe}>Baixe o app: </p>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <a href="https://apps.apple.com/br/app/voker%C3%AA/id1476149761">
              <img
                src={appstore}
                alt="selo app store"
                className={styles.appstore}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=br.com.hollic.vokere&hl=pt_BR&gl=US">
              <img src={playstore} alt="playstore" className={styles.appstore} />
            </a>
          </div>
          <p className={styles.direitos}>
            © 2022 Vokerê. Todos os direitos reservados.
          </p>
        </Box>
      </Grid>
    </footer>
  )
}

export default Footer
