import SecaoForm from './components/secao_form/secao_form'
import SecaoInvestir from './components/secao_investir/secao_investir'

const Franqueado = () => {
  return (
    <div>
      <SecaoForm />
      <SecaoInvestir />
    </div>
  )
}
export default Franqueado
