//CSS
import styles from '../secao_negociar/style.module.css'
//REACT
import React from 'react'
import { Link } from 'react-router-dom'
//CSS
import { Grid, Box } from '@mui/material'
//IMAGENS
const negocios = require('../../../../assets/images/negocios.png')

const SecaoNegociar = () => {
  return (
    <Box>
      <Box className={styles.fundo}>
        <Grid className={styles.div_texto}>
          <div className={styles.div_negocio}>
            <img src={negocios} className={styles.img_negocios} alt="maleta" />
            <p className={styles.b_t_negocio}>Negócios</p>
          </div>

          <div>
            <h1 className={styles.t_negocio}>Vamos negociar?</h1>
            <p className={styles.d_negocio}>
              Venhar negociar conosco e fazer parte da nossa equipe de franquias
              ou parceiros.
            </p>
          </div>
          <Grid sx={{ display: 'flex' }}>
            <Link to="/Franqueado">
              <div className={styles.div_franqueado}>
                <p className={styles.t_franquia}>Franquia</p>
              </div>
            </Link>
            <Link to="/Parceiro">
              <div className={styles.div_parceiro}>
                <p className={styles.t_parceria}>Parceria</p>
              </div>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SecaoNegociar
