import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

const checklist = require('../../../../assets/images/checklist.png')

const SecaoInvestir = () => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  return (
    <Box sx={{
      background: '#171717',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: size.x > 700 ? 'space-around' : 'space-between',
      flexWrap: 'wrap',
      paddingBottom: 2,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8.5,
        marginLeft: 1,
        marginRight: 1,
        width: size.x > 700 ? '50%' : '100%',
      }}>
        <h1 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '35px',
          lineHeight: '58px',
          color: '#F2EFEF',
        }}>
          Porque investir numa franquia Vokerê?
        </h1>
        <p style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '17px',
          letterSpacing: '0.045em',
          color: '#F2EFEF',
          textAlign: 'justify',
        }}>
          Temos o melhor e mais completo modelo de negócio em delivery de
          conveniência, oferecendo várias formas de faturamento para o negócio.{' '}
        </p>
      </Box>
      <Box
        sx={{
          marginTop: 8.5,
          marginBottom: 5,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <h1 style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          color: '#F2EFEF'
        }}>Diferenciais</h1>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Alta rentabilidade.</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Expansão agressiva.</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Melhores condições de preços.</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Alta margem de lucro.</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>
            Especialista em delivery de conveniência.
          </p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Suporte específico e adequado.</p>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={checklist} alt="checklist" style={{
            width: '54px',
            height: '63px',
          }} />
          <p style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            color: '#F2EFEF',
          }}>Treinamento detalhado.</p>
        </Box>
      </Box>
    </Box>
  )
}

export default SecaoInvestir
