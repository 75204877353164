//CSS
import { Box, Card, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react';

import styles from '../secao_form/style.module.css'
import Notificacao from '../../../../components/notification';

const mulher_joinha = require('../../../../assets/images/mulher_fazendo_joinha.png')
const SecaoForm = () => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  useEffect(() => (window.onresize = updateSize), []);

  const [nome, setNome] = useState('');
  const [cidade, setCidade] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box sx={{
      background: '#FFEC0D',
      marginTop: '95px',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      width: '100%',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <h1 className={styles.titulo}>Leve o Vokerê para a sua cidade.</h1>

        <img
          src={mulher_joinha}
          style={{
            height: size.x > 700 ? '411px' : '380px',
            marginTop: '210px',
            marginLeft: size.x > 1000 ? '-450px' : size.x > 700 ? '-385' : '-330px',
          }}
          alt="mulher segurando celular enquanto faz joinha com a outra mão"
        />
      </Box>

      <div
        style={{
          width: size.x > 700 ? 'auto' : '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '50px',
        }}
      >
        <Card sx={{
          border: '1px solid #FFFFFE',
          width: size.x > 700 ? '400px' : '95%',
          height: '523px',
          marginTop: size.x > 700 ? '35px' : '0px',
          background: '#FFFFFE',
          borderRadius: '25px',
          alignItems: 'center',
          textAlign: 'center',
        }} elevation={8}>
          <div className={styles.textoForm}>
            <h3 className={styles.t_form}>Seja franqueado do Vokerê</h3>
            <p className={styles.d_form}>Comece a vender mais</p>
            <div hidden>
              <label htmlFor="">Franqueado:</label>
              <input type="text" name="Tipo" value={'Franqueado'} />
            </div>
          </div>
          <input
            style={{
              paddingLeft: '14px',
              marginBottom: '12px',
              fontSize: '13px',
              width: size.x > 700 ? '316px' : '90%',
              height: '41px',
              background: '#F2EFEF',
              border: '1px solid rgba(194, 192, 192, 0.9)',
              borderRadius: '30px',
            }}
            type="text"
            name="Nome"
            placeholder="Digite seu nome completo: "
            required
            onChange={(e) => {
              const nomeFlag = e.target.value;
              setNome(nomeFlag);
            }}
          />
          <input
            style={{
              paddingLeft: '14px',
              marginBottom: '12px',
              fontSize: '13px',
              width: size.x > 700 ? '316px' : '90%',
              height: '41px',
              background: '#F2EFEF',
              border: '1px solid rgba(194, 192, 192, 0.9)',
              borderRadius: '30px',
            }}
            type="text"
            name="Cidade"
            placeholder="Cidade de interesse. "
            required
            onChange={(e) => {
              const cidadeFlag = e.target.value;
              setCidade(cidadeFlag);
            }}
          />
          <input
            style={{
              paddingLeft: '14px',
              marginBottom: '12px',
              fontSize: '13px',
              width: size.x > 700 ? '316px' : '90%',
              height: '41px',
              background: '#F2EFEF',
              border: '1px solid rgba(194, 192, 192, 0.9)',
              borderRadius: '30px',
            }}
            type="text"
            name="Telefone"
            placeholder="Numero WhatsApp: "
            required
            onChange={(e) => {
              const numeroFlag = e.target.value;
              setTelefone(numeroFlag);
            }}
          />
          <input
            style={{
              paddingLeft: '14px',
              marginBottom: '12px',
              fontSize: '13px',
              width: size.x > 700 ? '316px' : '90%',
              height: '41px',
              background: '#F2EFEF',
              border: '1px solid rgba(194, 192, 192, 0.9)',
              borderRadius: '30px',
            }}
            type="email"
            name="E-Mail"
            placeholder="E-Mail"
            required
            onChange={(e) => {
              const emailFlag = e.target.value;
              setEmail(emailFlag);
            }}
          />
          <Box sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
            <Box style={{
              display: 'flex',
              width: '316px',
            }}>
              <input
                style={{
                  color: '#FFEC0D',
                  background: '#FFEC0D',
                  border: '1px solid #FFFFFE',
                  borderRadius: '5px',
                }}
                type="checkbox"
                name="Validação: "
                value="Concordo que o Vokerê entre em contato comigo por telefone,
                  email e whatsapp."
                required
              />
              <p style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '19px',
                color: '#403F3F',
                textAlign: 'start',
                marginLeft: '10px',
              }}>
                Concordo que o Vokerê entre em contato comigo por telefone, email
                e whatsapp.
              </p>
            </Box>
          </Box>
          <input
            type="hidden"
            name="_next"
            value="https://euvokere.com.br//Agradecimento"
          />
          {
            isLoading ? 
            <CircularProgress/> : 
            <div className={styles.div_botao} onClick={async () => {
              function handleOnChange(email: string): boolean {
                  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                  if (re.test(email)) {
                      return true;
                  }
                  else {
                      return false;
                  }
              }

              if (cidade.length < 3){
                Notificacao('Error!', `Insira o nome da cidade`, 'danger');
                return;
              }
              if (telefone.length < 9){
                Notificacao('Error!', `Insira um numero de telefone`, 'danger');
                return;
              }
              if (email.length < 6 || handleOnChange(email) === false){
                Notificacao('Error!', `Insira um email válido`, 'danger');
                return;
              }
              if (nome.length < 4){
                Notificacao('Error!', `Insira um nome válido`, 'danger');
                return;
              }
              const body = {
                "assunto": `Gostaria de adquirir uma franquia para cidade de ${cidade}`,
                "mensagem": `Olá, meu nome é ${nome} e gostaria de adquirir uma franquia para a cidade de ${cidade}. Meu telefone é ${telefone} e o meu endereço de email é ${email}`
              }

              const url = "https://api.euvokere.com.br/api/v3/sendEmailBySite";

              setIsLoading(true);
              const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
              });

              setIsLoading(false);

              if (response.status === 200) {
                const data = await response.json();
                const mensagem = data.message;
                if (mensagem && mensagem.includes('sucesso')) {
                    Notificacao('Sucesso!', mensagem, 'success');
                    return true;
                }
        
                if (mensagem) {
                    Notificacao('Atenção!', mensagem, 'default');
                }
                return false;
              } 
              
            }}>
            <button className={styles.botao}>
              <p className={styles.t_botao}> Enviar</p>
            </button>
          </div>
          }
        </Card>
        <input
          type="hidden"
          name="_next"
          value="https://euvokere.com.br//Agradecimento"
        />
      </div>
    </Box>
  )
}
export default SecaoForm
