import { AppBar, Box, Button, Tab, Tabs, Toolbar, useMediaQuery, useTheme } from '@mui/material'
/*import * as React from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useState } from 'react-router-dom'

import { AppBar, Button, Divider, Toolbar } from '@mui/material'*/
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from '../header/style.module.css'
import DrawerComp from './DrawerComp'

const vokereLogo = require('../../assets/images/logo_fundo_preto.png')

const Header = () => {
  const [value, setValue] = useState()
  /*const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }*/
  const theme = useTheme()
  console.log(theme)
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  console.log(isMatch)

  const styleBtn = {
    height: 45,
    marginLeft: '3px',
    marginRight: '3px',
    textTransform: 'capitalize',
    background: '#F9146B',
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14.5,
    color: '#FFFFFE',
    borderRadius: 1,
    transition: 'border-color:0.2',
    '&:hover': {
      backgroundColor: '#b50246',
      filter: 'brightness(0.9)'
    }
  }

  return (
    <header>
      <React.Fragment>
        <AppBar
          sx={{
            backgroundColor: '#FFFFFF',
            boxShadow: 'none',
            position: 'absolute',
            margin: 0,
            width: '100%',
            display: 'flex',
            justifyContent: isMatch ? 'space-between' : 'center',
            alignItems: isMatch ? 'space-between' : 'center',
            marginTop: '5px',
          }}
        >
          <Toolbar>
            {isMatch ? (
              <Box sx={{
                display: 'flex',
                justifyContent: isMatch ? 'space-between' : 'center',
                alignItems: isMatch ? 'space-between' : 'center',
                width: '100%'
              }}>
                <DrawerComp />
                <img
                  src={vokereLogo}
                  className={styles.logo_celular}
                  alt="Logo do vokere"
                />
              </Box>
            ) : (
              <>
                {
                  /*
                  <img
                    src={vokereLogo}
                    className={styles.logo}
                    alt="Logo do vokere"
                  />
                  */
                }
                <Tabs
                  sx={{
                    marginLeft: 'auto',
                    textTransform: 'capitalize',
                    width: '100%',
                    display: 'flex',
                    justifyContent: isMatch ? 'space-between' : 'center',
                    alignItems: isMatch ? 'space-between' : 'center',
                    textAlign: isMatch ? 'space-between' : 'center',
                  }}
                  value={value}
                  onChange={(e, value) => setValue(value)}
                >
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '10px',
                  }}>
                    <Tab
                      label="Home"
                      component={Link}
                      to="/Home"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />
                    <Tab
                      label="Entregador"
                      component={Link}
                      to="/Entregador"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,

                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />
                    <Tab
                      label="Franqueado"
                      component={Link}
                      to="/Franqueado"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,

                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />
                    <Tab
                      label="Parceiro"
                      component={Link}
                      to="/Parceiro"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,

                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />   <Tab
                      label="Trabalhe Conosco"
                      component={Link}
                      to="/TrabalheConosco"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,

                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />
                    <Tab
                      label="Sobre"
                      component={Link}
                      to="/Sobre"
                      sx={{
                        textTransform: 'capitalize',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        /* identical to box height */

                        color: '#403F3F'
                      }}
                    />
                  </div>
                  <Button
                    href="/login"
                    sx={styleBtn}
                  >
                    Faça seu pedido
                  </Button>
                  <Button
                    href="https://onelink.to/6qh3rv"
                    sx={styleBtn}
                  >
                    Baixe o app
                  </Button>
                </Tabs>
              </>
            )}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </header>
  )
}

export default Header
